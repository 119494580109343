import React from 'react'
import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import classNames from 'classnames'
import styles from './TitleDivider.module.scss'

/**
 * @param {Object} props
 * @param {boolean|false} props.eyebrowBlack Use to set eyebrow 'black' variant
 */
function TitleDivider({
  eyebrow,
  eyebrowBlack = false,
  eyebrowClassName,
  title,
  subtitle,
  className,
  classNameTitleText,
  classNameSubtitleText,
  titleTag = 'h2',
  subtitleTag = 'h3',
  disableTitleTag = false,
  disableSubtitleTag = false,
}) {
  return (
    <div className={`${styles['title-divider']} ${className ? className : ''}`}>
      {eyebrow && (
        <Eyebrow
          content={eyebrow?.textContent}
          className={eyebrowClassName}
          data-testid="title-divider-eyebrow"
          black={eyebrowBlack}
        />
      )}
      {title && (
        // The initial display for the title will be an h2 with Ascend type style displayLg.
        // Responsive size changes are handled in global type style `section-title`
        <Typography
          data-testid="title-divider-title"
          className={classNames(
            'text-center text-[1.5rem] font-medium leading-[130%] lg:text-[2.25rem] lg:leading-[120%] lg:tracking-[-0.01em]',
            classNameTitleText
          )}
          variant="normal"
          content={title?.textContent || title}
          {...(!disableTitleTag && { component: titleTag })}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={classNames(styles['subtitle'], classNameSubtitleText)}
          content={subtitle.textContent}
          {...(!disableSubtitleTag && { component: subtitleTag })}
        />
      )}
    </div>
  )
}

export { TitleDivider }
